var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "coin-list d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("h1", { staticClass: "title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("lk.header.coinSettingsTrends"))),
            ]),
            _c(
              "CLoadingButtonCustom",
              {
                staticClass: "cbtn shadow-none",
                attrs: {
                  color: "success",
                  loading: _vm.saveChangeLoad,
                  disabled: _vm.saveChangeLoad,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.saveChange.apply(null, arguments)
                  },
                },
              },
              [
                _c("CIcon", { attrs: { name: "cil-save" } }),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("general.saveChanges"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "CCard",
        {
          staticClass: "ccard shadow-none overflow-hidden",
          attrs: { color: "second-card-bg" },
        },
        [
          _c("CCardHeader", { staticClass: "ccard-header" }, [
            _vm._v(_vm._s(_vm.$t("lk.coinList.coinsToDisplayInTrends"))),
          ]),
          _c(
            "CCardBody",
            { staticClass: "ccard-body overflow-auto" },
            [
              _vm.coinArray.length && !_vm.getCoinLoad
                ? [
                    _c(
                      "CRow",
                      { staticClass: "row-cols-2 row-cols-sm-4 row-cols-xl-6" },
                      _vm._l(_vm.coinArray, function (item, i) {
                        return _c(
                          "CCol",
                          { key: i, class: "coin-item" },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("CInputCheckbox", {
                              attrs: {
                                custom: true,
                                checked: item.show,
                                label: _vm.$t("lk.coinList.show"),
                              },
                              on: {
                                "update:checked": function ($event) {
                                  return _vm.$set(item, "show", $event)
                                },
                              },
                            }),
                            _c("CInputCheckbox", {
                              attrs: {
                                custom: true,
                                checked: item.show_binance_perp,
                                label: _vm.$t("lk.coinList.showPerp"),
                              },
                              on: {
                                "update:checked": function ($event) {
                                  return _vm.$set(
                                    item,
                                    "show_binance_perp",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.getCoinLoad
                ? _c(
                    "div",
                    { staticClass: "spinner-wrap" },
                    [_c("CSpinner", { attrs: { color: "primary" } })],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }